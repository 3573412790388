import React from "react";
import { graphql } from "gatsby";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
//import { StaticImage } from "gatsby-plugin-image";
//import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const PageResearch = ({ data, location }) => {
  const intl = useIntl();

  const posts = data.allContentfulClinicalPublications.edges;
  return (
    <Layout location={location} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `Clinical research I Cyberdyne Hybrid Assistive Limb I HAL`
            : `Klinische Studien I  Cyberdyne Hybrid Assistive Limb I HAL`
        }
        description={
          intl.locale === "en"
            ? `Clinical studies and trials with Cyberdyne's medical devices for  cerebrovascular disease, spinal cord injury, neuromuscular diseases and more.`
            : `Klinische Studien mit medizinischen Cyberdyne Produkten für zerebrovaskuläre Erkrankungen, Rückenmarksverletzungen, neuromuskuläre Erkrankungen und mehr.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/4ed5jzJStG5iNAHhenlLja/8d189746b04bee61da43ad833cc60540/E192.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-bls">
        <div className="container container-newslist">
          <h1 className="h1">
            {intl.locale === "en"
              ? `Clinical Publications`
              : `Klinische Studien`}
          </h1>

          <p className="message-label-bold txt txt-rot mb-8">
            {intl.locale === "en"
              ? `Clinical Publications`
              : `Klinische Studien`}
          </p>

          <div className="flex-row">
            <div className="flex-column-2">
              <h2 className="h1 txt txt-rot font-weight-light">2022</h2>
            </div>
            <div className="flex-column-10">
              <div className="news-card-wrap flex-row flex-wrap mx-n2">
                {posts &&
                  posts.map(({ node: post }) => {
                    if (post.publishYear === "2022") {
                      return (
                        <div key={post.slug} className="flex-column-6 p-2">
                          <div className="news-card">
                            <p className="slide-title__data font_inter">
                              {post.publishDate}
                            </p>
                            <h2 className="title">{post.title}</h2>
                            <p className="excerpt">
                              {post.results &&
                                post.results.results.substr(0, 120)}
                            </p>
                            <div className="news-link">
                              <Link to={`/research/${post.slug}`} className="">
                                Lesen
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    } else {return("");}
                  })}
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="flex-column-2">
              <h2 className="h1 txt txt-rot font-weight-light">2021</h2>
            </div>
            <div className="flex-column-10">
              <div className="news-card-wrap flex-row flex-wrap mx-n2">
                {posts &&
                  posts.map(({ node: post }) => {
                    if (post.publishYear === "2021") {
                      return (
                        <div key={post.slug} className="flex-column-6 p-2">
                          <div className="news-card">
                            <p className="slide-title__data font_inter">
                              {post.publishDate}
                            </p>
                            <h2 className="title">{post.title}</h2>
                            <p className="excerpt">
                              {post.results &&
                                post.results.results.substr(0, 120)}
                            </p>
                            <div className="news-link">
                              <Link to={`/research/${post.slug}`} className="">
                                Lesen
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    } else {return("");}
                  })}
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="flex-column-2">
              <h2 className="h1 txt txt-rot font-weight-light">2020</h2>
            </div>
            <div className="flex-column-10">
              <div className="news-card-wrap flex-row flex-wrap mx-n2">
                {posts &&
                  posts.map(({ node: post }) => {
                    if (post.publishYear === "2020") {
                      return (
                        <div key={post.slug} className="flex-column-6 p-2">
                          <div className="news-card">
                            <p className="slide-title__data font_inter">
                              {post.publishDate}
                            </p>
                            <h2 className="title">{post.title}</h2>
                            <p className="excerpt">
                              {post.results &&
                                post.results.results.substr(0, 120)}
                            </p>
                            <div className="news-link">
                              <Link to={`/research/${post.slug}`} className="">
                                Lesen
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    } else {return("");}
                  })}
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="flex-column-2">
              <h2 className="h1 txt txt-rot font-weight-light">2019</h2>
            </div>
            <div className="flex-column-10">
              <div className="news-card-wrap flex-row flex-wrap mx-n2">
                {posts &&
                  posts.map(({ node: post }) => {
                    if (post.publishYear === "2019") {
                      return (
                        <div key={post.slug} className="flex-column-6 p-2">
                          <div className="news-card">
                            <p className="slide-title__data font_inter">
                              {post.publishDate}
                            </p>
                            <h2 className="title">{post.title}</h2>
                            <p className="excerpt">
                              {post.results &&
                                post.results.results.substr(0, 120)}
                            </p>
                            <div className="news-link">
                              <Link to={`/research/${post.slug}`} className="">
                                Lesen
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    } else {return("");}
                  })}
              </div>
            </div>
          </div>
          {/*}
          <div className="flex-row">
            <div className="flex-column-2">
              <h2 className="h1 txt txt-rot font-weight-light">2020</h2>
            </div>
            <div className="flex-column-10">
              <div className="news-card-wrap flex-row flex-wrap mx-n2">
                {posts &&
                  posts.map(({ node: post }) => {
                    return (
                      <div key={post.slug} className="flex-column-6 p-2">
                        <div className="news-card">
                          <p className="slide-title__data font_inter">
                            {post.createdAt} | {post.category}
                          </p>
                          <h2 className="title">{post.title}</h2>
                          <p className="excerpt">
                            {post.content &&
                              documentToPlainTextString(
                                JSON.parse(post.content.raw)
                              ).substr(0, 120)}
                          </p>
                          <div className="news-link">
                            <Link to={`/news/${post.slug}`} className="">
                              Lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="flex-row">
            <div className="flex-column-2">
              <h2 className="h1 txt txt-rot font-weight-light">2019</h2>
            </div>
            <div className="flex-column-10">
              <div className="news-card-wrap flex-row flex-wrap mx-n2">
                {posts &&
                  posts.map(({ node: post }) => {
                    return (
                      <div key={post.slug} className="flex-column-6 p-2">
                        <div className="news-card">
                          <p className="slide-title__data font_inter">
                            {post.createdAt} | {post.category}
                          </p>
                          <h2 className="title">{post.title}</h2>
                          <p className="excerpt">
                            {post.content &&
                              documentToPlainTextString(
                                JSON.parse(post.content.raw)
                              ).substr(0, 120)}
                          </p>
                          <div className="news-link">
                            <Link to={`/news/${post.slug}`} className="">
                              Lesen
                            </Link>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        */}

          <div className="t-align-c">
            <button class="button-init link-border link-border-white">
              {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
            </button>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 txt mb-5">
            {intl.locale === "en" ? `Media Room` : `Multimedia`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  News, pressrelease, event, exhibition
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The significant existence of technology is to support, assist,
                  enhance, and regenerate functions of humans.
                </p>
                <Link to="/news/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Recruiting Inc.` : `Karriere`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  We envision a future where humans and technology are
                  seamlessly interfaced, feeding off of each other.
                </p>
                <Link to="/recruit/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
};

export default PageResearch;

export const pageQuery = graphql`
  query ($language: String) {
    allContentfulClinicalPublications(
      filter: {
        slug: { ne: "do-not-delete-sample" }
        node_locale: { eq: $language }
      }
    ) {
      edges {
        node {
          title
          slug
          results {
            results
          }
          publishDate(formatString: "DD. MMMM YYYY")
          publishYear: publishDate(formatString: "YYYY")
        }
      }
    }
  }
`;
